<template>
    <div :class="theme">
      <div id="button">
        <div v-if="scanner_active" id="containerStream">
          <QrStream @decode="onDecodeEmit"/>
          <button-close-stream @click="closeScanner"/>
        </div>
        
        <transition name="scan">
          <div id="badge" v-if="!scanner_active" @click="startScanner">
            <p>{{ data }}</p>
          </div>
        </transition>
      </div>
    </div>
  </template>
  
  <script>
  import { curr_theme } from '@/db/session_db';
  import { QrStream } from 'vue3-qr-reader';
  import { reactive, toRefs } from 'vue';
  import ButtonCloseStream from './ButtonCloseStream.vue';
  import api from '@/services/axios';
  import scannerSound from '@/assets/scanner_sound.mp3';
  
  export default {
    name: 'ButtonScanQr',
    
    props: {
      workshopId: {
        type: [String, Number],
        required: true,
        default: process.env.VUE_APP_WORKSHOP_ID
      }
    },
  
    components: {
      QrStream,
      ButtonCloseStream
    },
  
    data() {
      return {
        theme: curr_theme,
        scanner_active: false
      }
    },
  
    setup() {
      const state = reactive({
        data: "Scan QR"
      })
  
      return {
        ...toRefs(state),
      }
    },
  
    methods: {
        
        async onDecodeEmit(encodedData) {
          const audio = new Audio(scannerSound);
          audio.play();
        try {            
          let url = `attended_confirm_qr/${this.workshopId}/${encodeURIComponent(encodedData)}/`;
          url = url.replace(/%2B/g, '+').replace(/%2F/g, '/');
            
            const response = await api.get(url);
            
            this.$emit("gotNewScan", response.data);
            this.theme = response.data.status == 200 ? "theme-green" : "theme-red";
            this.data = "Scan Next QR";
        } catch (error) {
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            this.theme = "theme-red";
            
            let errorMessage = "فشل في معالجة رمز QR";
            if (error.response) {
                errorMessage = error.response.data?.message || "خطأ في الخادم";
            } else if (error.request) {
                errorMessage = "فشل الاتصال بالخادم";
            }
            this.$emit("scanError", errorMessage);
        }
    },
      startScanner() {
        this.scanner_active = true;
        this.$emit('newScan');
      },
  
      closeScanner() {
        this.scanner_active = false;
        this.theme = curr_theme;
        this.$emit('scanClosed');
      }
    }
  }
  </script>
  
  <style scoped>
  #containerStream {
    position: relative;
    height: 100%;
  }
  
  #button {
    position: relative;
    background: var(--striped-background);
    height: 80vw;
    border-radius: 15px;
    max-width: 400px;
    max-height: 400px;
    padding: 10px;
  }
  
  #badge {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 5px 30px 5px 30px;
    transform: translate(-50%, -50%);
    background-color: var(--main-color);
    border-radius: 15px;
    border: 2px solid var(--background-color);
    cursor: pointer;
    transition-delay: 0.5s;
  }
  
  p {
    color: var(--background-color);
    user-select: none;
  }
  
  .scan-enter-from, .scan-leave-to {
    opacity: 0;
  }
  
  .scan-enter-active, .scan-leave-active {
    transition: opacity 1s ease;
  }
  </style>